import {ActionTypes} from './companyJobsActions';
import config from 'config';

const mergeCandidates = (state, id, candidatesToMerge) => {
    const jobs = state.jobs.filter(x => x.id === id)
    const cands = (jobs[0].stats.candidates || []).slice();
    candidatesToMerge.candidates.forEach(cand => {
        const position = cands.findIndex(x => x.candidate_spec.candidate_id === cand.candidate_spec.candidate_id);
        const newCandidatePosition = position >= 0 ? position : cands.length;
        cands[newCandidatePosition] = cand;
    })
    jobs[0].stats.candidates = cands;
    return jobs[0];
}

const mergeMultipleJobs = (state, jobsToMerge) => {
    const jobs = (state.jobs || []).slice();
    jobsToMerge.forEach(job => {
        const position = jobs.findIndex(c => c.id === job.id);
        const newCompanyPosition = position >= 0 ? position : jobs.length;
        jobs[newCompanyPosition] = job;
    })
    return jobs;
}

const mergeJob = (state, job) => {
    return mergeMultipleJobs(state, [job]);
}

const appendJobs = (state, jobsToMerge) => {
    const jobs = (state.jobs || []).concat(jobsToMerge || []);
    return jobs;
}

const mergePrivateData = (state, payload) => {
    const {id, data} = payload;

    const jobs = (state.jobs || []).slice();
    const position = jobs.findIndex(c => c.id === id);
    if (position === -1) {
        return state;
    }
    jobs[position] = {...jobs[position], ...data};

    return {...state, jobs};
};

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.CREATE_JOB_REQUEST:
        nextState = {...state, loading: true, created: false};
        break;
    case ActionTypes.EDIT_JOB_REQUEST:
    case ActionTypes.AI_REQUIREMENTS_REQUEST:
    case ActionTypes.AI_UPDATE_REQUIREMENTS_REQUEST:
    case ActionTypes.FETCH_MORE_AI_APPLICANTS_REQUEST:
    case ActionTypes.FETCH_MATCHING_JOB_REQUEST:
    case ActionTypes.JOB_PUSH_REQUEST_REQUEST:
    case ActionTypes.AI_MATCH_CANDIDATES_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.CREATE_JOB_SUCCESS:
        nextState = {...state, loading: false, jobs: mergeJob(state, payload), created: true};
        break;
    case ActionTypes.EDIT_JOB_SUCCESS:
    case ActionTypes.AI_REQUIREMENTS_SUCCESS:
    case ActionTypes.AI_UPDATE_REQUIREMENTS_SUCCESS:
    case ActionTypes.FETCH_MATCHING_JOB_SUCCESS:
        nextState = {...state, loading: false, jobs: mergeJob(state, payload)};
        break;
    case ActionTypes.FETCH_JOBS_BY_IDS_SUCCESS:
        nextState = {...state, loading: false, jobs: mergeMultipleJobs(state, payload)};
        break;
    case ActionTypes.CREATE_JOB_FAILURE:
    case ActionTypes.EDIT_JOB_FAILURE:
    case ActionTypes.AI_REQUIREMENTS_FAILURE:
    case ActionTypes.AI_UPDATE_REQUIREMENTS_FAILURE:
    case ActionTypes.FETCH_MORE_AI_APPLICANTS_FAILURE:
    case ActionTypes.FETCH_MATCHING_JOB_FAILURE:
    case ActionTypes.JOB_PUSH_REQUEST_FAILURE:
    case ActionTypes.JOB_PUSH_REQUEST_SUCCESS:
    case ActionTypes.AI_MATCH_CANDIDATES_SUCCESS:
    case ActionTypes.AI_MATCH_CANDIDATES_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.FETCH_COMPANY_JOBS_REQUEST:
        nextState = {...state, jobsForCompany: payload.companyId, loading: true};
        if (payload.companyId !== state.jobsForCompany) {
            nextState.jobs = null;
        }
        break;
    case ActionTypes.FETCH_COMPANY_JOBS_FAILURE:
        nextState = {...state, jobs: null, loading: false, fetchError: true};
        break;
    case ActionTypes.PRIVATE_JOB_FETCHED:
        nextState = mergePrivateData(state, payload);
        break;
    case ActionTypes.FETCH_COMPANY_JOBS_SUCCESS: {
        const {jobs = [], last} = payload;
        nextState = {
            ...state,
            jobs, last,
            hasMore: jobs.length === config.jobsPagination,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_JOBS_SUCCESS: {
        const {jobs = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            jobs: appendJobs(state, jobs),
            last,
            hasMore: jobs.length === config.jobsPagination,
        };
        break;
    }
    case ActionTypes.FETCH_MORE_AI_APPLICANTS_SUCCESS: {
        const {stats, id} = payload;
        nextState = {...state, loading: false, job: mergeCandidates(state, id, stats)};
        break;
    }
    case ActionTypes.ADMIN_COMPANY_JOBS_COMPANY_CHANGED:
        nextState = {...state, filter: {}, last: null};
        break;
    case ActionTypes.FILTER_ADMIN_JOB_LIST:
        nextState = {...state, filter: payload, last: null};
        break;
    case ActionTypes.GENERATE_JOBS_REPORT_REQUEST:
        nextState = {...state, loadingFile: true};
        break;
    case ActionTypes.GENERATE_JOBS_REPORT_SUCCESS:
    case ActionTypes.GENERATE_JOBS_REPORT_FAILURE:
        nextState = {...state, loadingFile: false};
        break;
    case ActionTypes.ADMIN_JOB_LIST_SORT_CHANGED:
        nextState = {...state, sorting: payload[0]};
        break;
    case ActionTypes.ADMIN_COMPANY_JOBS_MOUNTING:
        nextState = {...state, sorting: null};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
