import _ from 'lodash';
import React from 'react';
import {takeLatest, take} from 'redux-saga/effects';
import {ActionTypes as ApplicantActionTypes} from 'modules/applicant';
import {ActionTypes as ReferrerActionTypes} from 'modules/referrer';
import {ActionTypes as CompanyActionTypes} from 'modules/companies';
import {ActionTypes as JobActionTypes} from 'modules/companyJobs';
import {ActionTypes as CustomerTypes} from 'modules/customer';
import {ActionTypes as UserActionTypes} from 'modules/users';
import {ActionTypes as NotifActionTypes} from 'modules/notifications';
import {ActionTypes as GroupActionTypes} from 'modules/group';
import {toastr} from 'react-redux-toastr'
import Flex from 'components/Flex';
import { FormattedMessage } from 'react-intl';
import defaultMessages, { getIntlMessage } from 'i18n/defaultMessages';
import ClickToCopyUnstyled from 'components/ClickToCopyUnstyled';

export function showToastr(options, action) {
    let {type, message} = options;
    const warning = _.get(action, 'payload._meta.warning');
    let toastrOptions = {}
    if (warning) {
        type = 'warning';
        message = getIntlMessage(`toaster_${warning}`);
    }
    const incidentId = _.get(action, 'payload.response.data._status.context.incidentId');
    if (incidentId) {
        toastrOptions = {timeOut: 30000, removeOnHover: false}
    }
    toastr[type](undefined, undefined, {
        ...toastrOptions,
        component: () => (
            <Flex className="toastr-content" centerChildren style={{minHeight: 50}}>
                <div style={{fontWeight: 'bold', fontSize: 20}}><FormattedMessage {...message} /></div>
                {incidentId && (
                    <div style={{marginTop: 10}}>
                        <FormattedMessage {...getIntlMessage(`toaster_contact_support`)} />
                        <div style={{marginTop: 10, fontSize: 12}}>
                        <ClickToCopyUnstyled textToCopy={incidentId} id="incidentId">
                            <button className="toastr-icon-btn">
                                <i className="fas fa-copy" />
                            </button>
                        </ClickToCopyUnstyled>
                            {incidentId}
                        </div>
                    </div>
                )}
            </Flex>
        )
    });
};

function* handleFailures() {
    while(true) {
        const action = yield take('*')
        if (action.error) {
            showToastr({message: defaultMessages.operation_failure, type: 'error'}, action);
        }
    }
}

export default [
    takeLatest([
        ApplicantActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS,
        ApplicantActionTypes.APPLICANTS_UPDATE_APPLICANT_SUCCESS,
        CompanyActionTypes.ADD_COMPANY_SUCCESS,
        CompanyActionTypes.EDIT_COMPANY_SUCCESS,
        CompanyActionTypes.BOT_REQUEST_SUCCESS,
        CompanyActionTypes.RESET_COMPANY_SUCCESS,
        JobActionTypes.CREATE_JOB_SUCCESS,
        JobActionTypes.EDIT_JOB_SUCCESS,
        JobActionTypes.AI_REQUIREMENTS_SUCCESS,
        JobActionTypes.AI_UPDATE_REQUIREMENTS_SUCCESS,
        JobActionTypes.AI_MATCH_CANDIDATES_SUCCESS,
        JobActionTypes.FETCH_MORE_AI_APPLICANTS_SUCCESS,
        JobActionTypes.JOB_PUSH_REQUEST_SUCCESS,
        ReferrerActionTypes.GENERATE_LINK_SUCCESS,
        ReferrerActionTypes.CREATE_REFERRER_SUCCESS,
        ReferrerActionTypes.REFERRER_REVOKE_NOTIF_SUCCESS,
        UserActionTypes.ADD_USER_SUCCESS,
        UserActionTypes.EDIT_USER_SUCCESS,
        UserActionTypes.DELETE_USER_SUCCESS,
        CustomerTypes.EDIT_CUSTOMER_SETTINGS_SUCCESS,
        NotifActionTypes.CREATE_JOB_NOTIF_SUCCESS,
        GroupActionTypes.ASSIGN_REFERRER_SUCCESS,
        GroupActionTypes.CREATE_GROUP_SUCCESS,
        GroupActionTypes.UPDATE_GROUP_SUCCESS,
        GroupActionTypes.DELETE_GROUP_SUCCESS,
        GroupActionTypes.ASSIGN_REFERRER_SUCCESS,
        GroupActionTypes.DELETE_GROUP_REFERRER_SUCCESS,
    ], showToastr, {message: defaultMessages.operation_success, type: 'success'}),
    takeLatest('STORE_LOADED', handleFailures),
];
