import React from 'react';
import { Route } from 'react-router-dom';
import {Switch} from 'react-router';
import AsyncComponent from 'components/AsyncComponent';
import Main from 'components/Main';
import Flex from 'components/Flex';
import ElevatedBox from 'components/ElevatedBox';
import ScrollToTop from './ScrollToTop';

const AsyncLogin = AsyncComponent(() => import('containers/login'));
const AsyncResetPassword = AsyncComponent(() => import('containers/resetPassword'));
const Accessibility = AsyncComponent(() => import('containers/accessibility'));
const AsyncLoginProviders = AsyncComponent(() => import('containers/loginProviders'));
const AsyncAdmin = AsyncComponent(() => import('containers/admin'));
const AsyncCompanyJobsRoute = AsyncComponent(() => import('containers/CompanyJobsRoute'));
const AsyncReferrerJobRoute = AsyncComponent(() => import('containers/JobRoute/referrerJobRouteRedux'));
const AsyncApplicantJobRoute = AsyncComponent(() => import('containers/JobRoute/applicantJobRouteRedux'));
const AsyncCustomerTermsRoute = AsyncComponent(() => import('containers/CustomerTermsRoute/customerTermsRouteRedux'));
const AsyncCompanyTermsRoute = AsyncComponent(() => import('containers/CompanyTermsRoute/companyTermsRouteRedux'));
const AsyncReferrerFormLinks = AsyncComponent(() => import('containers/ReferrerFormLinks/ReferrerFormLinksRedux'));
const AsyncCreateCompany = AsyncComponent(() => import('containers/adminCreateEditCompany/adminCreateCompanyRedux'));
const AsyncEditCompany = AsyncComponent(() => import('containers/adminCreateEditCompany/adminEditCompanyRedux'));
const AsyncAdminCompanyRoute = AsyncComponent(() => import('containers/CompanyRoute/adminCompanyRouteRedux'));
const AsyncPublicCompanyRoute = AsyncComponent(() => import('containers/CompanyRoute/publicCompanyRouteRedux'));
const AsyncPublicCompanyHeader = AsyncComponent(() => import('containers/CompanyRoute/publicCompanyHeaderRedux'));
const AsyncPublicCompanyFooter = AsyncComponent(() => import('containers/CompanyRoute/publicCompanyFooterRedux'));
const AsyncUnsubscribeRoute = AsyncComponent(() => import('containers/CompanyRoute/publicCompanyUnsubscribeRedux'));
const AsyncAdminCompanyJobsRoute = AsyncComponent(() => import('containers/adminCompanyJobsRoute'));
const AsyncAdminReferrersRoute = AsyncComponent(() => import('containers/adminReferrersRoute'));
const AsyncGroupsRoute = AsyncComponent(() => import('containers/GroupsList/groupsListRedux'));
const AsyncCreateJob = AsyncComponent(() => import('containers/createEditJob/createJobRedux'));
const AsyncEditJob = AsyncComponent(() => import('containers/createEditJob/editJobRedux'));
const AsyncEditAiJob = AsyncComponent(() => import('containers/editJobAi/editJobAiRedux'));
const AsyncAiJobStats = AsyncComponent(() => import('containers/editJobAi/jobAiStatsRedux'));
const AsyncApplicantList = AsyncComponent(() => import('containers/admin/applicantListRedux'));
const AsyncApplicantModal = AsyncComponent(() => import('containers/admin/applicantModalRedux'));
const AsyncApplicantFilter = AsyncComponent(() => import('containers/admin/applicantFilterRedux'));
const AsyncCompanyCover = AsyncComponent(() => import('containers/companyCover'));
const AsyncCompanyLogo = AsyncComponent(() => import('containers/CompanyLogo'));
const AsyncAdminUsersRoute = AsyncComponent(() => import('containers/adminUsersRoute'));
const AsyncUsersList = AsyncComponent(() => import('containers/adminUsersRoute/usersListRedux'));
const AsyncApiKeysList = AsyncComponent(() => import('containers/ApiKeysList/apiKeysListRedux'));
const AsyncAdminDashboard = AsyncComponent(() => import('containers/adminDashboardRoute'));
const AsyncAdminCompanyDashboard = AsyncComponent(() => import('containers/adminCompanyDashboardRoute'));
const AsyncCreateUser = AsyncComponent(() => import('containers/createEditUser/createUserRedux'));
const AsyncEditUser = AsyncComponent(() => import('containers/createEditUser/editUserRedux'));
const AsyncCreateApiKey = AsyncComponent(() => import('containers/createEditApiKey/createApiKeyRedux'));
const AsyncEditApiKey = AsyncComponent(() => import('containers/createEditApiKey/editApiKeyRedux'));
const AsyncEditSettings = AsyncComponent(() => import('containers/createEditSettings/editSettingsRedux'));
const AsyncReferrerWorth = AsyncComponent(() => import('containers/ReferrerWorth/referrerWorthRedux'));
const AsyncReferrerShareModal = AsyncComponent(() => import('containers/ReferrerShareModal/referrerShareModalRedux'));
const AsyncEnableNotifModal = AsyncComponent(() => import('containers/enableNotifModal/enableNotifModalRedux'));
const AsyncCreateReferrerModal = AsyncComponent(() => import('containers/createEditReferrer/createReferrerRedux'));
const AsyncNotificationSettings = AsyncComponent(() => import('containers/notificationsSettingsPage/notifsSettingsRedux'));
const AsyncCompanySettingsPage = AsyncComponent(() => import('containers/CompanySettingsPage/companySettingsRedux'));
const AsyncCompanyAdminSettingsPage = AsyncComponent(() => import('containers/CompanyAdminSettingsPage/companyAdminSettingsRedux'));
const AsyncNotifHistoryPage = AsyncComponent(() => import('containers/notifHistoryPage/notifHistoryPageRedux'));
const AsyncCreateNotifModal = AsyncComponent(() => import('containers/createNotifModal/createNotifModalRedux'));
const AsyncReferrerStats = AsyncComponent(() => import('containers/ReferrerStats/referrerStatsRedux'));
const AsyncCompanyAdminIntegrationsPage = AsyncComponent(() => import('containers/integrationsPage/integrationsPageRedux'));

export default () => (
    <ScrollToTop>
        <Switch>
            <Route exact path="/" component={AsyncLogin} />
            <Route exact path="/resetPassword" component={AsyncResetPassword} />
            <Route exact path="/accessibility" component={Accessibility} />
            <Route exact path="/login" component={AsyncLoginProviders} />
            <Route path="/admin" render={({match: adminMatch}) => (
                <AsyncAdmin match={adminMatch}>
                    <Route exact path={adminMatch.url} component={AsyncAdminDashboard} />
                    <Route path={`${adminMatch.url}/addCompany`} render={() => (
                        <Main noWrap>
                            <AsyncCreateCompany />
                        </Main>
                    )} />
                    <Route path={`${adminMatch.url}/applicants`} render={({match: applicantsMatch}) => (
                        <Main className="admin-applicants-route">
                            <ElevatedBox>
                                <AsyncApplicantFilter />
                                <AsyncApplicantList />
                                <Route exact path={`${applicantsMatch.url}/:applicantId`} component={AsyncApplicantModal} />
                            </ElevatedBox>
                        </Main>
                    )} />
                    <Route path={`${adminMatch.url}/companies/:company`}  render={({match: companyMatch}) => 
                        (
                            <AsyncAdminCompanyRoute match={companyMatch}>
                                <Switch>
                                    <Route exact path={`${companyMatch.url}`} component={AsyncAdminCompanyDashboard} />
                                    <Route path={`${companyMatch.url}`} render={({match: companyMatch}) =>
                                        <Main className="admin-cmp-route" noWrap horizontal>
                                            <Route exact path={`${companyMatch.url}/jobs/:jobId/notif`} component={AsyncCreateNotifModal} />
                                            <Switch>
                                                <Route exact path={`${companyMatch.url}/edit`} component={AsyncEditCompany} />
                                                <Route exact path={`${companyMatch.url}/jobs`} component={AsyncAdminCompanyJobsRoute} />
                                                <Route exact path={`${companyMatch.url}/jobs/:jobId/notif`} component={AsyncAdminCompanyJobsRoute} />
                                                <Route exact path={`${companyMatch.url}/jobs/:jobId/ai`} component={AsyncEditAiJob} />
                                                <Route exact path={`${companyMatch.url}/jobs/:jobId/ai/match`} component={AsyncAiJobStats} />
                                                <Route
                                                    path={`${companyMatch.url}/referrer-groups`}
                                                    render={props => (
                                                        <>
                                                            <AsyncGroupsRoute companyMatch={companyMatch} {...props} />
                                                            <Route
                                                                path={`${companyMatch.url}/referrer-groups/add`}
                                                                component={AsyncCreateReferrerModal}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Route
                                                    path={`${companyMatch.url}/referrers`}
                                                    render={props => (
                                                        <>
                                                            <AsyncAdminReferrersRoute companyMatch={companyMatch} {...props} />
                                                            <Route
                                                                path={`${companyMatch.url}/referrers/add`}
                                                                component={AsyncCreateReferrerModal}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <Route exact path={`${companyMatch.url}/notifications`} component={AsyncNotificationSettings}/>
                                                <Route exact path={`${companyMatch.url}/notif-history`} component={AsyncNotifHistoryPage}/>
                                                <Route exact path={`${companyMatch.url}/settings`} component={AsyncCompanySettingsPage}/>
                                                <Route exact path={`${companyMatch.url}/admin-settings`} component={AsyncCompanyAdminSettingsPage}/>
                                                <Route exact path={`${companyMatch.url}/integrations`} component={AsyncCompanyAdminIntegrationsPage}/>
                                                <Switch>
                                                    <Route exact path={`${companyMatch.url}/jobs/new`} component={AsyncCreateJob} />
                                                    <Route exact path={`${companyMatch.url}/jobs/:jobId`} component={AsyncEditJob} />
                                                    <Route exact path={`${companyMatch.url}/jobs/:jobId/new`} component={AsyncCreateJob} />
                                                </Switch>
                                            </Switch>
                                        </Main>
                                    } />
                                </Switch>
                            </AsyncAdminCompanyRoute>
                        )}
                    />
                    <Route path={`${adminMatch.url}/settings/users`}  render={({match: usersMatch}) => 
                        (
                            <AsyncAdminUsersRoute match={usersMatch}>
                                <Main className="admin-users-route">
                                    <Flex horizontal centerChildren mobileCol>
                                        <Route path={`${usersMatch.url}`} component={AsyncUsersList} />
                                        <AsyncCreateUser />
                                        <AsyncEditUser />
                                    </Flex>
                                </Main>
                            </AsyncAdminUsersRoute>
                        )}
                    />
                    <Route path={`${adminMatch.url}/settings/apiKeys`}  render={({match: usersMatch}) => (
                        <Main className="admin-apiKeys-page">
                            <Flex horizontal centerChildren mobileCol>
                                <AsyncApiKeysList />
                                <AsyncCreateApiKey />
                                <AsyncEditApiKey />
                            </Flex>
                        </Main>
                    )} />
                    <Route path={`${adminMatch.url}/settings/general`} render={() => (
                        <Main noWrap>
                            <AsyncEditSettings />
                        </Main>
                    )} />
                </AsyncAdmin>
            )} />

            {/* For when react-router releases a version after 4.2
                <Redirect exact from="/companies/:company" path="/companies/:company" to="/companies/:company/jobs" />
            */}
            <Route path="/r/companies/:company" component={({match}) => {
                return (
                    <AsyncPublicCompanyRoute match={match}>
                        <AsyncPublicCompanyHeader />
                        <Route path={`${match.url}`} component={AsyncCompanyCover} />
                        <Main className="cmp-route">
                            <Route path={`${match.url}/jobs`} component={AsyncReferrerWorth} />
                            <Route path={`${match.url}/jobs`} component={AsyncReferrerShareModal} />
                            <Route path={`${match.url}/jobs`} component={AsyncEnableNotifModal} />
                            <Flex stretch>
                                <Route path={`${match.url}/jobs`} component={AsyncReferrerFormLinks} />
                                <Route path={`${match.url}/jobs/:job`} component={AsyncReferrerJobRoute} />
                                <Route exact path={`${match.url}/jobs`} component={AsyncCompanyJobsRoute} />
                                <Route path={`${match.url}/terms`} component={AsyncCompanyTermsRoute} />
                                <Route path={`${match.url}/unsubscribe/:id`} component={AsyncUnsubscribeRoute} />
                            </Flex>
                        </Main>
                        <AsyncPublicCompanyFooter />
                        <AsyncReferrerStats />
                    </AsyncPublicCompanyRoute>
                );
                
            }} />
            <Route path="/companies/:company" render={({match: companyMatch}) => (
                <AsyncPublicCompanyRoute>
                    <AsyncPublicCompanyHeader />
                    <Route path={companyMatch.url} component={AsyncCompanyCover} />
                    <Main className="cmp-route">
                        <Route path={`${companyMatch.url}/jobs`} component={AsyncReferrerWorth} />
                        <Route path={`${companyMatch.url}/jobs`} component={AsyncReferrerShareModal} />
                        <Route path={`${companyMatch.url}/jobs`} component={AsyncEnableNotifModal} />
                        <Flex stretch>
                            <Route path={`${companyMatch.url}/jobs`} component={AsyncReferrerFormLinks} />
                            <Route path={`${companyMatch.url}/jobs/:job`} component={AsyncApplicantJobRoute} />
                            <Route exact path={`${companyMatch.url}/jobs`} component={AsyncCompanyJobsRoute} />
                            <Route path={`${companyMatch.url}/terms`} component={AsyncCompanyTermsRoute} />
                            <Route path={`${companyMatch.url}/unsubscribe/:id`} component={AsyncUnsubscribeRoute} />
                        </Flex>
                    </Main>
                    <AsyncPublicCompanyFooter />
                    <AsyncReferrerStats />
                </AsyncPublicCompanyRoute>
            )} />
            <Route path="/c/:customerId"s render={({match: customerMatch}) => (
                <Flex stretch>
                    <AsyncPublicCompanyHeader />
                    <Main className="customer-route">
                        <Route exact path={`${customerMatch.url}/terms`} component={AsyncCustomerTermsRoute} />
                    </Main>
                    <AsyncPublicCompanyFooter />
                </Flex>
            )} />
        </Switch>
    </ScrollToTop>
);